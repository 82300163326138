// Mirrored in:
// - src/styles/katal-theme/_variables.scss
// - src/test/nightwatch-helper.js
export const MOBILE_ENHANCEMENT_INCLUDE_CLASSNAME =
  'katal-mobile-enhancements-include';

// Mocking exclusively for Jest environments of our consumers
// Otherwise, if their tests use Popover, they will fail.
// https://stackoverflow.com/questions/39830580/jest-test-fails-typeerror-window-matchmedia-is-not-a-function
export const safeMatchMedia =
  window?.matchMedia ??
  function () {
    return {
      matches: false,
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };

export const mobileMediaQuery = safeMatchMedia('(max-width: 720px)');

export const isMobile = () =>
  mobileMediaQuery?.matches &&
  document.body?.classList.contains(MOBILE_ENHANCEMENT_INCLUDE_CLASSNAME);
