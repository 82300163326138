import React, { PropsWithChildren, useState } from 'react';

const Context = React.createContext({
    nextDemo: () => {}
});

export const DemoConsumer = Context.Consumer;

const DemoView = (props: PropsWithChildren) => {
    const [currentDemo, setCurrentDemo] = useState(1);

    const nextDemo = () => {
        setCurrentDemo(currentDemo + 1);
    };

    const children = React.Children.toArray(props.children);

    return (
        <Context.Provider value={{ nextDemo: nextDemo }}>
            {children.slice(0, currentDemo)}
        </Context.Provider>
    );
};

export default DemoView;
