import React from 'react';
import { MbmProvider } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from '../constants';
import DemoView from './DemoView';
import AlertDemo from './AlertDemo';
import LanguagePicker from './LanguagePicker';
import ButtonDemo from './ButtonDemo';
import ReactControlledDemo from './ReactControlledDemo';
import TabDemo from './TabDemo';
import DocumentationDemo from './DocumentationDemo';
import logo from 'src/images/logo.png';
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from '../helpers';

const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
        .withLocale(DEFAULT_LOCALE)
        .withDefaultLocale(DEFAULT_LOCALE)
        .build(),
    resolveBundleUrl: (
        resourcePackPath: string | undefined,
        locale: string,
        bundle: string
    ) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

const App = () => {
    return (
        <MbmProvider {...mbmOptions}>
            <h1>Hello world! From AtoZPortfolioApp</h1>
            <img src={logo} />
            <LanguagePicker />
            <DemoView>
                <AlertDemo />
                <ButtonDemo />
                <ReactControlledDemo />
                <TabDemo />
                <DocumentationDemo />
            </DemoView>
        </MbmProvider>
    );
};

export default App;
