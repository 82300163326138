import React from 'react';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import { KatDropdown, KatRow, KatColumn } from '@amzn/katal-react';
import { getLocalizationContext } from '../helpers';
import { DEFAULT_LOCALE } from '../constants';

import * as KatalMetrics from '@amzn/katal-metrics';
import initialMetricsPublisher from '../metrics';

const locales = [
    { name: 'English', value: 'en-US' },
    { name: 'Spanish', value: 'es-ES' },
    { name: 'Italian', value: 'it-IT' },
    { name: 'French', value: 'fr-FR' },
    { name: 'German', value: 'de-DE' },
    { name: 'Japanese', value: 'ja-JP' },
    { name: 'Chinese', value: 'zh-CN' },
    { name: 'Korean', value: 'ko-KR' },
];

const LanguagePicker = () => {
    const { setLocalizationContext } = useLocalizationContext();

    const handleChange = (e: CustomEvent<{ value: string }>) => {
        const language = e.detail.value;

        const localeChangeMetricsPublisher =
            initialMetricsPublisher.newChildActionPublisherForMethod(
                'LocaleChange'
            );
        localeChangeMetricsPublisher.publish(
            new KatalMetrics.Metric.String('locale', language || 'NONE')
        );

        setLocalizationContext(getLocalizationContext(language));
    };

    return (
        <KatRow>
            <KatColumn md={12}>
                Select your preferred language:
                <KatDropdown
                    data-testid="language-picker"
                    options={locales}
                    value={DEFAULT_LOCALE}
                    onChange={handleChange}
                />
            </KatColumn>
        </KatRow>
    );
};

export default LanguagePicker;
