interface FitHandlerProps {
  element: HTMLElement & { maxHeight?: string };
  innerContainer: HTMLElement;
  flipUppable: boolean;
  additionalOptions?: {
    additionalPaddingBelow: number;
  };
}

export function fitHandler(
  element: FitHandlerProps['element'],
  innerContainer: FitHandlerProps['innerContainer'],
  flipUppable: FitHandlerProps['flipUppable'],
  additionalOptions?: FitHandlerProps['additionalOptions']
) {
  if (!element.offsetParent) {
    return;
  }

  const fitHandler = new FitHandler(
    element,
    innerContainer,
    flipUppable,
    additionalOptions
  );
  fitHandler.fit();

  return fitHandler;
}

class FitHandler {
  private flipUppable: boolean;
  private element: HTMLElement & { maxHeight?: string };
  private innerContainer: HTMLElement;
  private availableSpaceAbove: number;
  private availableSpaceBelow: number;

  constructor(
    element: FitHandlerProps['element'],
    innerContainer: FitHandlerProps['innerContainer'],
    flipUppable: FitHandlerProps['flipUppable'],
    additionalOptions?: FitHandlerProps['additionalOptions']
  ) {
    this.flipUppable = flipUppable;
    this.element = element;
    this.innerContainer = innerContainer;
    if (this.isDroppedUp()) {
      this.removeDropUp();
    }
    const triggerHeight = element.offsetHeight;

    // We want to leave some empty space before we reach the top/bottom of the window
    const PADDING = 50;
    const scrollableParent = this.getOverflowParent(this.element);
    const parentOffset = scrollableParent
      ? scrollableParent.getBoundingClientRect()
      : { top: 0, height: window.innerHeight };
    const offsetTop =
      this.element.getBoundingClientRect().top - parentOffset.top;
    this.availableSpaceAbove = offsetTop - triggerHeight - PADDING;
    this.availableSpaceBelow =
      parentOffset.height -
      offsetTop -
      PADDING -
      (additionalOptions?.additionalPaddingBelow || 0);
  }

  dropUp() {
    this.element.setAttribute('drop-up', '');
  }

  removeDropUp() {
    this.element.removeAttribute('drop-up');
  }

  isDroppedUp() {
    return this.element.hasAttribute('drop-up');
  }

  setMaxHeight(maxHeight) {
    if (!this.innerContainer || this.element.maxHeight) {
      return;
    }

    if (!maxHeight) {
      this.innerContainer.style.maxHeight = null;
      return;
    }

    this.innerContainer.style.maxHeight = `${maxHeight}px`;
    this.innerContainer.style.overflow = 'auto';
  }

  getOverflowParent(element) {
    let parent = element.parentElement;

    while (parent && getComputedStyle(parent).overflow === 'visible') {
      parent = parent.parentElement;
    }

    return parent;
  }

  scrollToHighlightedOption() {
    if (!this.innerContainer && this.scrollTo) {
      return;
    }

    const scrollToOption = this.element.querySelector<HTMLElement>('.selected');

    if (!scrollToOption) {
      this.innerContainer.scrollTop = 0;
      return;
    }

    this.innerContainer.scrollTop = scrollToOption.offsetTop;
  }

  fit() {
    // Reset max height if it's not the drop up render
    if (!this.isDroppedUp()) {
      this.setMaxHeight(null);
    }

    const elementHeight = this.element.offsetHeight;
    const innerContainerHeight = this.innerContainer.offsetHeight;

    const combinedHeight = elementHeight + innerContainerHeight;

    // If the dropdown does fit drop it down
    if (this.availableSpaceBelow > combinedHeight) {
      // It can be the already shrinked dropdown case when we need to scroll
      /*noop*/
      // If dropping it up solves the problem, drop it up
    } else if (this.availableSpaceAbove > combinedHeight && this.flipUppable) {
      this.dropUp();
    } else if (this.availableSpaceBelow > this.availableSpaceAbove) {
      this.setMaxHeight(this.availableSpaceBelow);
      // There is not enough space set max height
    } else if (this.flipUppable) {
      this.setMaxHeight(this.availableSpaceAbove);
      this.dropUp();
    }

    this.scrollToHighlightedOption();
  }
}
