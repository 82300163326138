import React from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import { KatAlert, KatRow, KatColumn } from '@amzn/katal-react';
import { DemoConsumer } from './DemoView';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from '../helpers';

const AlertDemo = () => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    return (
        <DemoConsumer>
            {({ nextDemo }) => (
                <KatRow id="AlertDemo" className="section">
                    <KatColumn md={12}>
                        <KatAlert
                            id={'greeting'}
                            data-testid="greeting"
                            header={t(bundle, 'greeting_header')}
                            description={t(bundle, 'greeting_description')}
                            onDismiss={nextDemo}
                        />
                    </KatColumn>
                </KatRow>
            )}
        </DemoConsumer>
    );
};

export default AlertDemo;
