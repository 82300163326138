import { html, property, query } from 'lit-element';
import {
  KatLitElement,
  Keys,
  register,
  event,
  EventEmitter,
} from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './tabs.lit.scss';
import { KatTabOverflow } from './tabs-overflow';
import { KatTab } from './tabs-tab';
import { OverflowController } from './controller/overflow-controller';
import { TabStrings } from './strings';
import { OVERFLOW_CHILD_TAB_NOT_SELECTED } from './tab-constants';

// NOTE: accessibility tips taken from https://inclusive-components.design/tabbed-interfaces/

/**
 * @component {kat-tabs} KatalTabs Tabs allow users to switch between sections of related content on a single page.
 * @slot default Should be filled with `kat-tab` elements.
 * @guideline Do Ensure that tab-id properties of all child kat-tabs elements are unique.
 * @guideline Do Use tabs for organizing discrete blocks of information.
 * @guideline Do Use no more than seven tabs in a tab view to help maintain an uncluttered UI and reduce cognitive load for customers.
 * @guideline Do All kat-tabs children must be direct children.
 * @guideline Do Use spans for slots. Using anything else like a div results in UI alignment issues.
 * @guideline Dont Don't use tabs as steps or navigation.
 * @guideline Dont Don't hide error messages or critical information inside tabs.
 * @guideline Dont Don't use tabs to create flows.
 * @subcomponent ./tabs-tab.ts
 * @example SampleTabs {"selected": "2", "content": "<kat-tab status=\"NEW\" tab-id=\"1\" label=\"tab1\">tab 1 content</kat-tab><kat-tab tab-id=\"2\" label=\"tab2\">tab 2 content</kat-tab>"}
 * @example Slots {"selected": "2", "content": "<kat-tab tab-id=\"1\"><span slot=\"label\"><kat-icon name=\"account_balance_wallet\" size=\"small\"></kat-icon>tab1</span><kat-icon slot=\"status\" name=\"ac_unit\" size=\"small\"></kat-icon>tab 1 content</kat-tab><kat-tab tab-id=\"2\"><span slot=\"label\">tab2</span>tab 2 content</kat-tab>"}
 * @example Metric {"selected": "2", "content": "<kat-tab status=\"New\" tab-id=\"1\" label=\"Neutral\" secondary-label=\"US sales only\" metric=\"Sales\" trend=\"neutral\" trend-polarity=\"neutral\">tab 1 content</kat-tab><kat-tab tab-id=\"2\" label=\"Decrease\" secondary-label=\"-28% weekly\" metric=\"Shipping Delay\" trend=\"decrease\" trend-polarity=\"positive\">tab 2 content</kat-tab><kat-tab tab-id=\"3\" label=\"Increase\" secondary-label=\"That can't be good\" metric=\"Latency\" trend=\"increase\" trend-polarity=\"negative\">tab 3 content</kat-tab>"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-tabs')
export class KatTabs extends KatLitElement {
  /** The name of the tab currently being displayed. Defaults to showing the first tab. */
  @property()
  selected?: string;

  /** The number of tabs before they start getting put into an overflow tab */
  @property({ attribute: 'tabs-before-overflow' })
  tabsBeforeOverflow?: number;

  /**
   * When true, forces tabs to expand to the full-width the the tab container.
   * When false, tabs will only expand to the width of their inner content.
   * Defaults to false.
   */
  @property({ attribute: 'full-width-tabs' })
  fullWidthTabs?: boolean = false;

  static get styles() {
    return [baseStyles, styles];
  }

  _selectedTab = null;
  _userInitiated = false;
  overflowController: OverflowController | null = null;
  overflowTab: KatTabOverflow | null = null;
  childTabs: (KatTab | KatTabOverflow)[] = [];

  /** Fires when the selected tab has changed. The event detail contains "selected" which is the target tab to display. The event can be cancelled to prevent the tab from changing. */
  @event('change', { cancelable: true })
  private _change: EventEmitter<{ selected: string }>;

  @query('.root')
  _root: HTMLElement;

  @query('#insert-here')
  _insert_location: HTMLElement;

  constructor() {
    super();

    this._listeners = [
      {
        target: window,
        listeners: [['resize', this._handleResize.bind(this)]],
      },
      [
        'keydown',
        e => {
          if (
            ![
              TabStrings.KatTabElement,
              TabStrings.KatTabOverflowElement,
            ].includes(e.target.tagName)
          )
            return;

          if (e.keyCode === Keys.ArrowLeft || e.keyCode === Keys.ArrowRight) {
            e.preventDefault();
            e.stopPropagation();

            const tab = this._selectedTab;

            const nextTab = !tab
              ? this.children[0]
              : e.keyCode === Keys.ArrowLeft
              ? tab.previousElementSibling
              : tab.nextElementSibling;

            if (this.overflowController) {
              const {
                overflowController,
                overflowController: { overflowTab },
              } = this;

              if (
                e.target.tagName === TabStrings.KatTabElement &&
                nextTab?.tagName === TabStrings.KatTabOverflowElement &&
                !nextTab.isOverflowVisible
              ) {
                return overflowController.arrowIntoOverflow();
              }

              // handle navigating when the overflow tab is open
              if (overflowTab.isOverflowVisible) {
                return overflowController.overflowVisibleArrow(e, nextTab);
              }

              // Overflow child navigation when Overflow is closed
              if (
                !overflowTab.isOverflowVisible &&
                overflowTab.overflowSelected !== OVERFLOW_CHILD_TAB_NOT_SELECTED
              ) {
                return overflowController.overflowNavigationWhenClosed(e);
              }
            }

            if (nextTab) {
              this._setSelected(nextTab.tabId);
              return;
            }

            this._setSelected(this.selected);

            return;
          }

          if (e.keyCode === Keys.ArrowDown) {
            // focus the content of the current tab
            e.preventDefault();
            e.stopPropagation();
            const tab = this._selectedTab;
            tab?.focusContent();
          }
        },
      ],
    ];
  }

  connectedCallback() {
    super.connectedCallback();

    // If the user has specified a tabsBeforeOverflow number and more tabs than the specified amount are present instantiate controller
    if (
      this.tabsBeforeOverflow &&
      this.tabsBeforeOverflow < this.children.length
    ) {
      this.overflowController = new OverflowController(this);
    }
  }

  getChildTabs() {
    this.childTabs = Array.from(this.children) as (KatTab | KatTabOverflow)[];

    return this.childTabs;
  }

  firstUpdated() {
    super.firstUpdated();
    this._selectTab();

    const observer = new MutationObserver(() => this._handleResize());
    observer.observe(this, {
      attributes: true,
      subtree: true,
      childList: true,
    });
  }

  updated(changed) {
    if (changed.has('selected')) {
      this._selectTab();
    }
  }

  _selectTab(e?: Event) {
    if (e) e.stopPropagation();

    if (this.overflowController) {
      // Handle clicking on an overflow child tab when a regular tab was selected before
      if (this.overflowController.isInitialOverflowChildBeingClicked) {
        this.overflowController._clickOverflowChildTabForTheFirstTime();
        return;
      }

      // Handle overflow child tab selection for the second or more time, until a non overflow child tab is selected
      if (this.selected === TabStrings.KatHiddenTab) {
        this.overflowController._selectTab();
        return;
      }
    }

    let selectedTab;
    const childTabs = this.getChildTabs();

    Array.from(childTabs).forEach((tab, i) => {
      const selected =
        // auto-select the first tab if no selected id is set
        (i === 0 && !this.selected) ||
        // prevent multiple tabs from being selected at once
        (!selectedTab && tab.tabId === this.selected);
      tab.selected = selected;
      if (selected) {
        selectedTab = tab;
      }
    });

    // sets selected tab...
    this._selectedTab = selectedTab;
    if (!this.selected && selectedTab) {
      this.selected = selectedTab.tabId;
    }

    // Prevent component loading from switching focus
    if (this._userInitiated) {
      selectedTab?.focus();
    }

    // A primary child tab was clicked, reset overflow selection
    if (this.overflowController) {
      this.overflowController.overflowTab.overflowSelected =
        OVERFLOW_CHILD_TAB_NOT_SELECTED;
    }

    this._handleResize();
  }

  _setSelected(id) {
    const cancelled = !this._change.emit({ selected: id });
    if (cancelled) return;

    this._userInitiated = true;

    // When utilizing overflowTabs, this.selected is always handled in the overflowController
    if (this.overflowController) {
      this.overflowController._setSelected(id);
      return;
    }

    this.selected = id;
  }

  _handleResize(e?: Event) {
    if (e) e.stopPropagation();

    const tab = this._selectedTab;
    if (!tab || !tab._content) return;
    const updateMargin = () => {
      const style = getComputedStyle(tab._content);

      const height =
        tab._content.offsetHeight +
        styleToNumber(style.marginTop) +
        styleToNumber(style.marginBottom);
      this._root.style.marginBottom = `${height}px`;
    };
    updateMargin();
    // we do this again in a timeout so we can catch any height changes caused
    // by a child tabs element updating after we've already updated
    setTimeout(updateMargin, 10);
    if (this._userInitiated) {
      this._userInitiated = false;
    }
  }

  _handleSelected(e) {
    e.stopPropagation();
    this._setSelected(e.detail.id);
  }

  _overflowBlur(e) {
    this.overflowController._handleOverflowBlur(e);
  }

  render() {
    return html`
      <div
        class="root"
        role="tablist"
        @select=${this._handleSelected}
        @_invalidate=${this._selectTab}
        @_overflowBlur=${this._overflowBlur}
      >
        <slot></slot>
      </div>
    `;
  }
}

function styleToNumber(prop: string) {
  return parseInt(prop.replace(/px$/, ''), 10);
}
