import React, { useEffect, useState } from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import { KatAlert, KatRow, KatColumn } from '@amzn/katal-react';
import ContinueButton from './ContinueButton';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from 'src/helpers';

const ReactControlledDemo = () => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
    const [alertVariant, setAlertVariant] = useState<KatAlert.Variant>('info');

    useEffect(() => {
        const interval = window.setInterval(() => {
            const alertVariants: KatAlert.Variant[] = [
                'info',
                'warning',
                'success',
                'danger',
            ];

            setAlertVariant(
                alertVariants[Math.floor(Math.random() * alertVariants.length)]
            );
        }, 60000);

        return () => {
            window.clearInterval(interval);
        };
    }, []);

    return (
        <div id="ReactControlledDemo" className="section">
            <KatRow noGutters>
                <KatColumn xs={12}>
                    <h2>{t(bundle, 'demo_javascript_header_text')}</h2>
                </KatColumn>
            </KatRow>

            <KatRow>
                <KatColumn md={6}>
                    <KatAlert
                        id={'alert_variant_demo'}
                        data-testid="alert_variant_demo"
                        variant={alertVariant}
                        header={t(bundle, 'alert_variant_demo_description')}
                    />
                </KatColumn>
                <KatColumn md={6}>
                    <p>{t(bundle, 'alert_variant_explanation_text')}</p>
                </KatColumn>
            </KatRow>

            <ContinueButton
                buttonId="continue_button_section4"
                nextSection="section4"
            />
        </div>
    );
};

export default ReactControlledDemo;
