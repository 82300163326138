import { property, query } from 'lit-element';
import { html, nothing } from 'lit-html';
import {
  KatLitElement,
  register,
  nextUniqueId,
  event,
  EventEmitter,
} from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import { checkSlots } from '../../shared/slot-utils';
import styles from './tabs-tab.lit.scss';

/**
 * @component {kat-tab} KatTab A tab element used to populate a kat-tabs element.
 * @slot default Elements without a named slot attribute appear in content of the tab pane.
 * @slot label The contents of the tab's label. Takes precedence over the `label` attribute.  Must be "SPAN"
 * @slot status An additional label to mark a tab with special significance. Takes precedence over status attribute.
 */

@register('kat-tab')
export class KatTab extends KatLitElement {
  /** The ID of the tab. Should be unique among the set of tabs. The `selected` attribute of the parent `kat-tabs` element will update to this value when this tab is selected. */
  @property({ attribute: 'tab-id' })
  tabId: string;

  /** The contents of the tab's label. */
  @property()
  label?: string;

  /** A secondary label for additional context. */
  @property({ attribute: 'secondary-label' })
  secondaryLabel?: string;

  /** The name of a metric to be displayed in the tab contents. An important summary of the information underneath the tab. */
  @property()
  metric?: string;

  /**
   * The trend of a metric being displayed. Intended to be used with the `metric` property.
   * @enum {value} increase Increase in the observed value.
   * @enum {value} decrease Decrease in the observed value.
   * @enum {value} neutral No variation in the observed value.
   * @enum {value} missing No data is available.
   */
  @property()
  trend?: 'increase' | 'decrease' | 'neutral' | 'missing';

  /**
   * The polarity of the trend line.
   * @enum {value} positive The observed value has positive impact.
   * @enum {value} negative The observed value has negative impact.
   * @enum {value} neutral The observed value has no impact.
   */
  @property({ attribute: 'trend-polarity' })
  trendPolarity?: 'positive' | 'negative' | 'neutral' = 'neutral';

  /** An additional label to mark a tab with special significance.
   * Multiple statuses can be applied through the `status` slot. */
  @property()
  status?: string;

  /** A read-only attribute, whether this tab is selected. To programmatically set the selected tab, use the parent kat-tabs' `selected` property instead. */
  @property()
  selected?: boolean;

  /** A read-only attribute, whether this tab is selected. To programmatically set the selected tab, use the parent kat-tabs' `selected` property instead. */
  @property({ attribute: 'overflow-tab-selected' })
  overflowTabSelected?: boolean;

  @event('select', {
    bubbles: true,
    cancelable: true,
  })
  private _select: EventEmitter<{ id: string }>;

  /** @private */
  @event('_invalidate', true)
  private _invalidate: EventEmitter;

  /** @private */
  @event('_overflowChildBlur', true)
  private _overflowChildBlur: EventEmitter<{ relatedTarget: string | null }>;

  static get styles() {
    return [baseStyles, styles];
  }

  _uniqueId = nextUniqueId();

  @query('.label')
  private _label: HTMLElement;

  @query('.content')
  private _content: HTMLElement;

  private _childrenChanged() {
    this.requestUpdate();
  }

  updated(changed) {
    if (changed.has('tabId')) {
      this._invalidate.emit();
    }
  }

  focus() {
    this?._label?.focus();
  }

  focusContent() {
    this._content.focus();
  }

  _handleClick() {
    this.focus();
  }

  _handleBlur(e) {
    this._overflowChildBlur.emit({ relatedTarget: e.relatedTarget });
  }

  _mouseDownHandler() {
    this._select.emit({ id: this.tabId });
  }

  _keydown(e) {
    if (e.key === 'Enter') {
      this._select.emit({ id: this.tabId });
    }
  }

  renderTrend() {
    const iconMapping = {
      increase: 'trending_up',
      decrease: 'trending_down',
      neutral: 'trending_neutral',
      missing: 'remove',
    };

    return html`
      ${!this.trend
        ? nothing
        : html`
            <div class="trend">
              <kat-icon
                class="${this.trendPolarity}"
                name="${iconMapping[this.trend]}"
                size="small"
                part="tab-trend-icon"
              ></kat-icon>
            </div>
          `}
    `;
  }

  renderSecondaryLabel() {
    return html`
      ${!this.secondaryLabel
        ? nothing
        : html`<div class="secondary-label">${this.secondaryLabel}</div>`}
    `;
  }

  renderMetric() {
    return html`
      ${!this.metric
        ? nothing
        : html`<div class="metric-label">${this.metric}</div>`}
    `;
  }

  render() {
    const slots = checkSlots(this);
    const statusDataContent = this.status || slots.status ? true : false;

    const statusRendered = html`
      <div class="status" data-content="${statusDataContent}">
        <slot name="status" @slotchange=${this._childrenChanged}>
          <span>${this.status}</span>
        </slot>
      </div>
    `;

    return html`
      <div
        id=${this._uniqueId}
        class="label"
        role="tab"
        aria-selected=${!!this.selected}
        tabindex="0"
        @click=${this._handleClick}
        @blur=${this._handleBlur}
        @mousedown=${this._mouseDownHandler}
        @keydown=${this._keydown}
      >
        <div class="label__inner">
          <slot name="label">${this.label}</slot>
          ${statusRendered}
        </div>
        ${!this.metric && !this.trend
          ? nothing
          : html`
              <div class="metric">
                ${this.renderMetric()} ${this.renderTrend()}
              </div>
            `}
        ${this.renderSecondaryLabel()}
        <span class="ring"></span>
      </div>
      <div
        class="content"
        role="tabpanel"
        aria-labelledby=${this._uniqueId}
        tabindex="0"
      >
        <slot></slot>
      </div>
    `;
  }
}
