import React from 'react';
import { KatButton, KatRow, KatColumn } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';
import { DemoConsumer } from './DemoView';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from 'src/helpers';

import initialMetricsPublisher from '../metrics';

interface Props {
    nextSection: string;
    buttonId: string;
}

const ContinueButton = ({ buttonId, nextSection }: Props) => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    const handleMetrics = () => {
        const actionMetricsPublisher =
            initialMetricsPublisher.newChildActionPublisherForMethod(
                'ButtonClick'
            );
        actionMetricsPublisher.publishCounterMonitor('clicks', 1);
        actionMetricsPublisher.publishString('buttonId', buttonId);
        actionMetricsPublisher.publishString('nextSection', nextSection);
    };

    return (
        <DemoConsumer>
            {({ nextDemo }) => (
                <KatRow>
                    <KatColumn xs={12}>
                        <KatButton
                            id={buttonId}
                            data-testid={buttonId}
                            className="float-right"
                            variant="primary"
                            label={t(bundle, 'continue_button_label')}
                            onClick={() => {
                                handleMetrics();
                                nextDemo();
                            }}
                        />
                    </KatColumn>
                </KatRow>
            )}
        </DemoConsumer>
    );
};

export default ContinueButton;
