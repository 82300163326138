import { html, property } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import { nothing } from 'lit-html';
import { ifNotNull } from '../../utils/directives';
import {
  KatLitElement,
  Keys,
  register,
  nextUniqueId,
  event,
  EventEmitter,
} from '../../shared/base';
import { formInputMap } from '../../utils/form-input-map';
import { checkSlots, cleanDefaultSlot } from '../../shared/slot-utils';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './checkbox.lit.scss';

/**
 * @component {kat-checkbox} KatalCheckbox Checkboxes offer users an independent, binary choice. When used in a group, checkboxes allow users to evaluate each option independently and select one, multiple, or none.
 * @slot default The contents of the checkbox label
 * @status Production
 * @theme flo
 * @example Basic {"label": "Are you sure?", "checked":"false", "constraint-label": ""}
 * @example Constraint {"label": "Are you Sure", "constraint-label":"This means an action will happen", "checked":"true"}
 * @guideline Do Use checkboxes to allow customers to select any number independent of options including zero, one, or several.
 * @guideline Do Use sentence case with clear, concise labels.
 * @guideline Do Use at least one of these labels: label, constriant-label, aria-label, or aria-labelledby to for screen readers.
 * @guideline The label used for screen readers will use the first available attribute in this order: aria-label, aria-labelledby, label, constraint-label.
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-checkbox')
export class KatCheckbox extends KatLitElement {
  /** The name that will be used when sending form data. */
  @property()
  name?: string;

  /** The value that is used when sending form data. Defaults to "on". */
  @property({ reflect: false })
  value = 'on';

  /** The text that is displayed to the user. */
  @property()
  label?: string;

  /** Optional text for the checkbox displayed below the normal label */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /** Used to label the checkbox when label or constraintLabel doesn't make sense. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /** The id of another element used to label the checkbox for accessibility purposes. */
  @property({ attribute: 'kat-aria-labelledby' })
  katAriaLabelledby?: string;

  /** The state of the checkbox. */
  @property()
  checked?: boolean;

  /** Determines if the checkbox is eligible to be clicked or not. */
  @property()
  disabled?: boolean;

  /** Sets the indeterminate state. */
  @property()
  indeterminate = false;

  static get styles() {
    return [baseStyles, styles];
  }

  /** Fires whenever the checkbox value is changed. */
  @event('change', true)
  private _change: EventEmitter<{ checked: boolean }>;

  constructor() {
    super();

    cleanDefaultSlot(this);

    this._uniqueLabelId = nextUniqueId();
    this._uniqueConstraintLabelId = nextUniqueId();
  }

  @formInputMap([
    {
      tag: 'input',
      name: (component: KatCheckbox) => component.name,
      isNeeded: (component: KatCheckbox) => component.isFormInputNeeded(),
      setup: (component: KatCheckbox, input: HTMLInputElement) =>
        component.setupFormInput(input),
    },
  ])
  updated(changedProperties) {
    super.updated(changedProperties);
  }

  isFormInputNeeded() {
    return !(this.disabled || !this.name);
  }

  setupFormInput(input: HTMLInputElement) {
    input.setAttribute('type', 'checkbox');
    input.value = this.value;
    this.checked && input.setAttribute('checked', 'true');
    input.indeterminate = this.indeterminate;
  }

  onKeyDown(evt) {
    if (evt.keyCode === Keys.Enter || evt.keyCode === Keys.Space) {
      this.onClick(evt);
    }
  }

  onClick(evt) {
    // We do not want the original event to bubble up.
    // The implementation of the component should be hidden.
    evt.stopImmediatePropagation();
    if (this.disabled) {
      return;
    }

    const checked = !this.checked;
    this.checked = checked;

    this._change.emit({ checked });
  }

  focus() {
    const checkbox = this.shadowRoot.querySelector('.checkbox') as any;
    checkbox?.focus();
  }

  render() {
    // Accessibility State: https://www.w3.org/TR/wai-aria-1.1/#checkbox
    let ariaLabel = undefined;
    let ariaLabelledBy = undefined;

    if (this.katAriaLabel) {
      ariaLabel = this.katAriaLabel;
    } else if (this.katAriaLabelledby) {
      ariaLabelledBy = this.katAriaLabelledby;
    } else if (this.label) {
      ariaLabelledBy = this._uniqueLabelId;
    }

    let ariaChecked;
    if (this.indeterminate) {
      ariaChecked = 'mixed';
    } else {
      ariaChecked = this.checked ? 'true' : 'false';
    }

    const label = this.label
      ? html`<kat-label
          id=${this._uniqueLabelId}
          part="checkbox-label"
          class="kat-checkbox-label-text"
          aria-hidden="true"
          text=${this.label}
        ></kat-label>`
      : nothing;

    const constraintLabel = this.constraintLabel
      ? html`<kat-label
          id=${this._uniqueConstraintLabelId}
          part="checkbox-constraint-label"
          class="kat-checkbox-constraint-text"
          variant="constraint"
          aria-hidden="true"
          text=${this.constraintLabel}
        ></kat-label>`
      : nothing;

    const tabindex = this.disabled ? null : '0';
    const classes = {
      checkbox: true,
      checked: this.checked,
      disabled: this.disabled,
      indeterminate: this.indeterminate,
    };
    const slots = checkSlots(this);
    const text =
      !this.label && !this.constraintLabel && !slots.default
        ? nothing
        : html`<div class="text-container" @click=${this.onClick}>
            <slot>${label}${constraintLabel}</slot>
          </div>`;

    return html`<div
        @click=${this.onClick}
        @keydown=${this.onKeyDown}
        part="checkbox-check"
        class=${classMap(classes)}
        tabindex=${tabindex}
        role="checkbox"
        aria-checked=${ifNotNull(ariaChecked)}
        aria-disabled=${this.disabled ? 'true' : 'false'}
        aria-label=${ifNotNull(ariaLabel)}
        aria-labelledby=${ifNotNull(ariaLabelledBy)}
        aria-describedby=${ifNotNull(
          this.constraintLabel ? this._uniqueConstraintLabelId : undefined
        )}
      >
        ${this.checked || this.indeterminate
          ? html`<svg
              class="checkbox__icon"
              focusable="false"
              viewBox="0 0 10 9"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d=${this.checked
                  ? 'M4 9L0 5.24l1.47-1.38L3.79 6l4.53-6L10 1.19z'
                  : 'M0 3h10v2.5H0z'}
              />
            </svg>`
          : nothing}
      </div>
      ${text}`;
  }
}
