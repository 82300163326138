import React from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import { KatButton, KatRow, KatColumn } from '@amzn/katal-react';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from 'src/helpers';

const DocumentationDemo = () => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    const handleDocumentationClicked = () => {
        window.open('https://katal.amazon.dev', '_blank');
    };

    return (
        <KatRow id="DocumentationDemo" className="section">
            <KatColumn xs={12}>
                <KatButton
                    className="float-right"
                    data-testid="documentation-button"
                    variant="primary"
                    label={t(bundle, 'documentation_label')}
                    onClick={handleDocumentationClicked}
                />
            </KatColumn>
        </KatRow>
    );
};

export default DocumentationDemo;
