import React, { useState } from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import {
    KatTabs,
    KatTab,
    KatButton,
    KatRow,
    KatColumn,
} from '@amzn/katal-react';
import ContinueButton from './ContinueButton';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from 'src/helpers';

const TabDemo = () => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
    const [tabs, setTabs] = useState<
        { children: string; tabId: string; label: string }[]
    >([]);
    const [tabIndex, setTabIndex] = useState<number>(4);

    const handleAddTab = () => {
        const tabId = tabIndex.toString(10);
        const label = `Tab ${tabId}`;
        const children = `Auto generated tab: ${label}`;

        setTabs([...tabs, { tabId, label, children }]);
        setTabIndex(tabIndex + 1);
    };

    return (
        <div id="TabDemo" className="section">
            <KatRow noGutters>
                <KatColumn xs={12}>
                    <h2>{t(bundle, 'demo_complex_header_text')}</h2>
                </KatColumn>
            </KatRow>

            <KatRow noGutters>
                <KatColumn md={6}>
                    <KatRow>
                        <KatColumn xs={12}>
                            <KatTabs selected="tabsel">
                                <KatTab
                                    tabId="tab1"
                                    label={t(bundle, 'tab_demo_name', {
                                        tabId: 1,
                                    })}
                                >
                                    {t(bundle, 'tab_demo_text', {
                                        tabId: 1,
                                    })}
                                </KatTab>
                                <KatTab
                                    tabId="tabsel"
                                    label={t(bundle, 'tab_demo_selected_name')}
                                >
                                    {t(bundle, 'tab_demo_selected_text')}
                                </KatTab>
                                <KatTab
                                    tabId="tab2"
                                    label={t(bundle, 'tab_demo_name', {
                                        tabId: 2,
                                    })}
                                >
                                    {t(bundle, 'tab_demo_text', {
                                        tabId: 2,
                                    })}
                                </KatTab>
                                <KatTab
                                    tabId="tab3"
                                    label={t(bundle, 'tab_demo_name', {
                                        tabId: 3,
                                    })}
                                >
                                    {t(bundle, 'tab_demo_text', {
                                        tabId: 3,
                                    })}
                                </KatTab>
                                {tabs.map((tab) => {
                                    const { tabId } = tab;
                                    return (
                                        <KatTab
                                            key={tabId}
                                            tabId={tabId}
                                            label={t(bundle, 'tab_demo_name', {
                                                tabId,
                                            })}
                                        >
                                            {t(
                                                bundle,
                                                'tab_demo_generated_text',
                                                {
                                                    tabId,
                                                }
                                            )}
                                        </KatTab>
                                    );
                                })}
                            </KatTabs>
                        </KatColumn>
                    </KatRow>
                    <KatRow>
                        <KatColumn xs={12}>
                            <KatButton
                                data-testid="add-tab-button"
                                variant="secondary"
                                label={t(bundle, 'addTab_label')}
                                onClick={handleAddTab}
                            />
                        </KatColumn>
                    </KatRow>
                </KatColumn>
                <KatColumn md={6}>
                    <p>{t(bundle, 'components_containing_html_text')}</p>
                </KatColumn>
            </KatRow>

            <ContinueButton
                buttonId="continue_button_section5"
                nextSection="documentation"
            />
        </div>
    );
};

export default TabDemo;
