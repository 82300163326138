import React from 'react';
import { KatButton, KatRow, KatColumn } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';
import ContinueButton from './ContinueButton';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from 'src/helpers';

const ButtonDemo = () => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    return (
        <div id="ButtonDemo" className="section">
            <KatRow noGutters>
                <KatColumn xs={12}>
                    <h2>{t(bundle, 'demo_components_header_text')}</h2>
                </KatColumn>
            </KatRow>

            <KatRow noGutters>
                <KatColumn md={6}>
                    <KatRow>
                        <KatColumn md={4}>
                            <KatButton
                                id={'primary_button'}
                                variant="primary"
                                label={t(bundle, 'primary_button_label')}
                            />
                        </KatColumn>
                        <KatColumn md={8}>
                            <p>
                                &lt;kat-button variant=&quot;primary&quot;
                                label=&quot;Primary
                                Button&quot;&gt;&lt;/kat-button&gt;
                            </p>
                        </KatColumn>
                    </KatRow>
                    <KatRow>
                        <KatColumn md={4}>
                            <KatButton
                                variant="secondary"
                                label={t(bundle, 'secondary_button_label')}
                            />
                        </KatColumn>
                        <KatColumn md={8}>
                            <p>
                                &lt;kat-button variant=&quot;secondary&quot;
                                label=&quot;Secondary
                                Button&quot;&gt;&lt;/kat-button&gt;
                            </p>
                        </KatColumn>
                    </KatRow>
                </KatColumn>
                <KatColumn md={6}>
                    <p>{t(bundle, 'custom_elements_text')}</p>
                    <p>{t(bundle, 'design_approved_text')}</p>
                </KatColumn>
            </KatRow>

            <ContinueButton
                buttonId="continue_button_section3"
                nextSection="section3"
            />
        </div>
    );
};

export default ButtonDemo;
